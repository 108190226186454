<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_SCHEDULE" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_SCHEDULE" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_SCHEDULE" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- Name -->
                    <div class="form-group">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="schedule.name"
                            required
                            minlength="{{ minLength }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <!-- Start Time -->
                    <div class="form-group position-relative">
                        <label for="startTimeInput" [ngClass]="{ 'is-invalid': form.submitted && startTimeInput.errors }"
                            >{{ "START_DATE/TIME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && startTimeInput.errors }">
                            <input
                                class="form-control"
                                id="startTimeInput"
                                name="startTimeInput"
                                (click)="toggleFromPicker()"
                                [(ngModel)]="startTime"
                                #startTimeInput="ngModel"
                                placeholder="{{ 'SELECT_START_DATE/TIME' | translate }}"
                                required
                                (keydown)="onlyDelete($event)"
                                autocomplete="off"
                                [ngClass]="{ 'is-invalid': form.submitted && startTimeInput.errors }"
                            />
                            <button
                                class="btn btn-outline-primary"
                                (click)="clearFromDate()"
                                type="button"
                                title="{{ 'CLEAR_START_DATE/TIME' | translate }}"
                                *ngIf="startTime"
                            >
                                <fa-icon icon="times"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="toggleFromPicker()"
                                type="button"
                                title="{{ 'SELECT_START_DATE/TIME' | translate }}"
                                *ngIf="!startTime"
                            >
                                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                            </button>
                        </div>
                        <div *ngIf="startTimeInput.invalid" class="invalid-feedback">
                            <div *ngIf="startTimeInput.errors.required">{{ "START_DATE/TIME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                        <div class="picker-container absolute" *ngIf="showFromPicker" (clickOutside)="clickOutsideFromPicker()">
                            <div class="arrow"></div>
                            <div class="picker-content shadow-sm">
                                <fa-icon icon="times-circle" class="close-icon" (click)="closeFromPicker()"></fa-icon>
                                <zx-date-time-picker
                                    name="startTimePicker"
                                    [(ngModel)]="startTime"
                                    (ngModelChange)="fromDateChanged($event)"
                                    [allDates]="true"
                                    [useBrowserTime]="true"
                                ></zx-date-time-picker>
                            </div>
                        </div>
                    </div>
                    <!-- End Time -->
                    <div class="form-group position-relative">
                        <label
                            for="endTimeInput"
                            translate
                            [ngClass]="{ 'is-invalid': form.submitted && (endTimeInput.errors || checkDate(startTime, endTime)) }"
                            >END_DATE/TIME</label
                        >
                        <div class="input-group" [ngClass]="{ 'is-invalid': form.submitted && (endTimeInput.errors || checkDate(startTime, endTime)) }">
                            <input
                                class="form-control"
                                id="endTimeInput"
                                name="endTimeInput"
                                (click)="toggleToPicker()"
                                [(ngModel)]="endTime"
                                #endTimeInput="ngModel"
                                placeholder="{{ 'SELECT_END_DATE/TIME' | translate }}"
                                (keydown)="onlyDelete($event)"
                                autocomplete="off"
                                [ngClass]="{ 'is-invalid': form.submitted && (endTimeInput.errors || checkDate(startTime, endTime)) }"
                            />
                            <button
                                class="btn btn-outline-primary"
                                (click)="clearToDate()"
                                type="button"
                                title="{{ 'CLEAR_END_DATE/TIME' | translate }}"
                                *ngIf="endTime"
                            >
                                <fa-icon icon="times"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="toggleToPicker()"
                                type="button"
                                title="{{ 'SELECT_END_DATE/TIME' | translate }}"
                                *ngIf="!endTime"
                            >
                                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                            </button>
                        </div>
                        <div *ngIf="form.submitted && endTime && checkDate(startTime, endTime)" class="invalid-feedback">
                            <div>{{ "END_DATE/TIME" | translate }} {{ "MUST_BE_AFTER" | translate }} {{ "START_DATE/TIME" | translate }}.</div>
                        </div>
                        <div class="picker-container absolute" *ngIf="showToPicker" (clickOutside)="clickOutsideToPicker()">
                            <div class="arrow"></div>
                            <div class="picker-content shadow-sm">
                                <fa-icon icon="times-circle" class="close-icon" (click)="closeToPicker()"></fa-icon>
                                <zx-date-time-picker
                                    name="endTimePicker"
                                    [(ngModel)]="endTime"
                                    (ngModelChange)="toDateChanged($event)"
                                    [onlyFutureDates]="true"
                                    [useBrowserTime]="true"
                                ></zx-date-time-picker>
                            </div>
                        </div>
                    </div>
                    <!-- Timezone -->
                    <div class="form-group">
                        <label for="time_zone">
                            {{ "TIME_ZONE" | translate }}
                        </label>
                        <ng-select
                            id="time_zone"
                            name="time_zone"
                            bindLabel="text"
                            [items]="timeZones"
                            [clearable]="false"
                            placeholder="{{ 'SELECT_TIME_ZONE' | translate }}"
                            [(ngModel)]="selectedTimeZone"
                        >
                        </ng-select>
                    </div>
                    <!-- Frequency -->
                    <fieldset class="form-group bordered mb-0">
                        <legend>{{ "FREQUENCY" | translate }}</legend>
                        <div class="form-check">
                            <input type="radio" id="once" name="once" class="form-check-input" value="once" [(ngModel)]="type" />
                            <label class="form-check-label" for="once">{{ "ONCE" | translate }}</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="daily" name="daily" class="form-check-input" value="daily" [(ngModel)]="type" />
                            <label class="form-check-label" for="daily">{{ "DAILY" | translate }}</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="weekly" name="weekly" class="form-check-input" value="weekly" [(ngModel)]="type" />
                            <label class="form-check-label" for="weekly">{{ "WEEKLY" | translate }}</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="monthly" name="monthly" class="form-check-input" value="monthly" [(ngModel)]="type" />
                            <label class="form-check-label" for="monthly">{{ "MONTHLY" | translate }}</label>
                        </div>
                        <div class="form-check">
                            <input
                                type="radio"
                                id="last_day_of_month"
                                name="last_day_of_month"
                                class="form-check-input"
                                value="last_day_of_month"
                                [(ngModel)]="type"
                            />
                            <label class="form-check-label" for="last_day_of_month">{{ "LAST_DAY_OF_THE_MONTH" | translate }}</label>
                        </div>
                        <!-- Every -->
                        <div class="input-group align-items-center">
                            <div class="input-group-prepend">
                                <div class="input-group">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" id="every" name="every" class="form-check-input" value="every" [(ngModel)]="type" />
                                        <label class="form-check-label" for="every" [ngClass]="{ 'is-invalid': form.submitted && time.errors }">{{
                                            "EVERY" | translate
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group-div maxw-80px">
                                <input
                                    type="number"
                                    class="form-control"
                                    id="time"
                                    name="time"
                                    [min]="1"
                                    pattern="^\d+$"
                                    [disabled]="type !== 'every'"
                                    [(ngModel)]="period"
                                    required="type == 'every'"
                                    #time="ngModel"
                                    [ngClass]="{ 'is-invalid': form.submitted && time.errors }"
                                />
                            </div>
                            <ng-select
                                id="interval"
                                name="interval"
                                bindLabel="int"
                                bindValue="int"
                                [items]="intervalOptions"
                                [clearable]="false"
                                [disabled]="type !== 'every'"
                                [(ngModel)]="interval"
                                class="ms-3 minw-90px"
                            >
                            </ng-select>
                            <div [ngClass]="{ 'is-invalid': form.submitted && time.errors }"></div>
                            <div *ngIf="time.invalid" class="invalid-feedback">
                                <div *ngIf="time.errors.required">{{ "NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="time.errors.pattern">{{ "NUMBER" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.</div>
                            </div>
                        </div>
                    </fieldset>
                    <!-- Error -->
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
